.portfolio-item  {
    // width: 300px;
    // box-sizing: border-box;
    // display: flex;
    // flex-direction: column;
    // justify-content: center;
    // align-items: center;
    overflow: hidden;
    margin: 25px 25px;
}


.portfolio-preview-main {height: 700px;}

.portfolio-preview-main img {
    height: 750px;
    width: 100%;
}

.zoomOnHover {
    overflow: hidden; 
    img {transition: all .5s ease-in-out;}
}
  
.portfolio-item:hover .zoomOnHover img,
.zoomOnHover:hover img{ 
    transform: scale(1.1);
}

@media screen and (max-width: 1200px) {
    .portfolio-preview-main {height: 500px;}
    .portfolio-preview-main img {height: 500px;}
}

@media screen and (max-width: 774px) {
    .portfolio-preview-main {
        height: unset;
        width: 400px;}
    .portfolio-preview-main img {height: unset;}
}

@media screen and (max-width: 500px) {
    .portfolio-preview-main {
        width: 340px;}
}