.catchy-quote {
    font-family: 'WindSong', cursive;
    font-size: 60px;
    color: black;
    // font-weight: bolder;
    margin: 30px 0;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .catchy-quote {
        font-size: 40px;
    }
  }