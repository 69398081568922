.default-btn {
    width: 200px;
    height: 60px;
    border-radius: 50px;
    border: none;
    letter-spacing: 3px;
    // font-weight: bolder;
    margin: 10px;
    -webkit-box-shadow: 4px 4px 28px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 28px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 28px 0px rgba(34, 60, 80, 0.2);
    transition: all .2s ease-in-out;
}

.default-btn:hover {
    -webkit-box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.2) inset;
-moz-box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.2) inset;
box-shadow: 0px 5px 18px 2px rgba(0, 0, 0, 0.2) inset;
}

.default-btn:active {
    -webkit-box-shadow: 0px 5px 24px 2px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0px 5px 24px 2px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0px 5px 24px 2px rgba(34, 60, 80, 0.2) inset;
}

.crimson {
    background-color: #822838;
    color: white;
}

// .crimson:hover {
//     background-color: #5e1f2a;
// }

.lime {
    background-color: #E4DA77;
}

// .lime:hover {
//     background-color: #a19a53;
// }

.white {
    background-color: white;
}

.white:hover {
    background-color: white;
    color: black;
    // -webkit-box-shadow: 4px 4px 28px 0px rgba(34, 60, 80, 0.2);
    // -moz-box-shadow: 4px 4px 28px 0px rgba(34, 60, 80, 0.2);
    // box-shadow: 4px 4px 28px 0px rgba(34, 60, 80, 0.2);
}
