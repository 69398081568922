.portfolio-preview {
    height: 700px;
    background-position: center;
    // background-size: cover;
    margin-bottom: 70px;
    transition: all .5s ease-in-out;
}

.portfolio-preview:hover {
    background-size: 115%;
    transition: all .5s ease-in-out;
}

.photo-item {
    width: 500px;
    margin: 20px 0px;
    cursor: pointer;
}

.masonry-container  div {
    margin: 0 auto;
}

.masonry-container {
    width: 90vw;
}

@media screen and (max-width: 1200px) {
    .portfolio-preview {
        height: 600px;
        margin-bottom: 10px;
    }
}

@media screen and (max-width: 1100px) {
    .photo-item {
        width: 90vw;
    }
}

@media screen and (max-width: 750px) {
    .portfolio-preview {
        height: fit-content;
        margin-bottom: 10px;
    }
}