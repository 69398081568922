.footer {
    width: 100%;
    // background-color: var(--main-theme-color);
    box-sizing: border-box;
    position: relative;
    z-index: 10;
}

.footer-bg {
    position: absolute;
    top: 200px;
    width: 100%;
    height: 200px;
    background-color: var(--main-theme-color);
    z-index: -1;
}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
} 

.footer .row {
    margin-top: 25px;
}

.block-link {
    display: block;
}

.contact-img {
    width: 40px;
    margin: 0px 5px;
    border-radius: 50px;
}

@media screen and (max-width: 771px) {
    .footer-bg {
        height: 320px;
    }
}

@media screen and (max-width: 350px) {
    .footer-bg {
        height: 330px;
    }
}