.certificate__image {
    width: 30vw;
    margin: 10px 10px;
}
  
.cert-in-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
  
.cert-in-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
}

.mywed-badge img {
    width: 200px;
}
  
@media screen and (max-width: 1300px) {
    .certificate__image {
      width: 40vw;
    }
}
  
@media screen and (max-width: 800px) {
    .cert-in-row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
    }
    .certificate__image {
      width: 60vw;
    }
}