.about-us-container {
    z-index: 100;
}

.about-us-img {
    width: 500px;
    margin: 20px;
    z-index: 90;
    transition: all .4s ease-in-out;
}

@media screen and (max-width: 450px) {
    .about-us-img {
        width: 350px;
    }
}

.about-us-par {
    text-indent: 20px;
}