.insta-feed-wrap {
    max-width: 90vw;
}

.insta-post-icon {
    width: 250px;
    height: 250px;
}

@media screen and (max-width: 1200px) {
    .insta-post-icon {
        width: 300px;
        height: 300px;
    }
}