.fullwidth-banner {
    width: 100%;
    height: 70vh;
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    margin: 10px 0 30px 0;
    a {
      color: white;
    }
}

.fullwidth-banner.position-bottom {
  background-position: bottom;
}

.fullwidth-banner h1,
.fullwidth-banner h3 { 
    width: 400px;
} 

@media only screen and (max-device-width: 1366px) {
    .fullwidth-banner {
      background-attachment: scroll;
    }
  }