.why-us-img {
    position: relative;
    width: 30vw;
    max-width: 400px;
    -webkit-box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
    -moz-box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
    box-shadow: 4px 4px 34px 0px rgba(34, 60, 80, 0.2);
    z-index: 10;
}

// @media screen and (max-width:900px) {
    
// }

.benefits-list-item {
    list-style-type: none;
    margin: 15px 0px;
}

.benefits-list-item::before {
    content: "·  ";
}