a {
    color: black;
}

.nav-header {
    width: 100%;
    height: 70px; 
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100;
}

.lang-options-header {
    margin-right: 10px;
}

.lang-link {
    display: inline;
    padding: 0 5px;
    cursor: pointer;
}

.current-lang {
    text-decoration: underline;
}

.lang-img {
    width: 30px;
    transition: all .2s ease-in-out;
}

.lang-img:hover {
    transform: scale(1.1);
}

@media screen and (max-width: 600px) {
    .footer .contact-text,
    .nav-header .contact-text {
        display: none;
    }
    
}